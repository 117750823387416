.CTA_container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.7em 1.25em;


    /*position: relative;*/
    /*width: 166px;*/
    //width: min-content;
    /*height: 52px;*/

    /* blue */


    border-radius: 12px;

    cursor: pointer;



    transition: all 0.2s ease-in;

    border: 2px solid #2548F1;
}
.CTA_text{

    //width: 126px;
    //height: 26px;

    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    /*font-size: 16px;*/
    font-size: 1em;
    line-height: 26px;
    /* identical to box height, or 162% */

    display: flex;
    align-items: center;

    /* white */

    /*color: #FFFFFF;*/


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;

}


.primaryContainer{
    background: #2548F1;

}
.primaryText{
    color: #FFFFFF;
}






.secondaryContainer{
    /*background: #2548F1;*/
}
.secondaryText{
    color: #2548F1;
}






.tertiaryContainer{
    /*background: #030303;*/
    border: 2px solid #030303;
}
.tertiaryText{
    color: #030303;
}




//Hover proprety only on desktop -> superior to 1024px
@media (min-width: 1024px){

    .primaryContainer:hover{

        background: rgba(255, 255, 255, 0);

        color: #2548F1;


    }
    .primaryContainer:hover p:first-child{
        color: #2548F1;

    }

    .secondaryContainer:hover{

        background: #2548F1;

    }
    .secondaryContainer:hover p:first-child{
        color: #FFFFFF;

    }

    .tertiaryContainer:hover{

        background: #030303;

        color: #2548F1;


    }
    .tertiaryContainer:hover p:first-child{
        color: #FFFFFF;

    }


}



.mobilePrimaryContainer{

    display: none !important;

    padding: 0.4em 0;

    border-radius: 9px;
    width: calc(100% - 4px);
    align-self: center;

    align-items: center;
    align-content: center;
    justify-content: center;
    //background: #ad217c;
}

.mobilePrimaryText{
    font-size: 0.82em;
    //font-size: 0.2em !important;
}


@media (max-width: 1024px){
    .mobilePrimaryContainer {
        display: flex !important;
    }
}


