/* Réinitialisation CSS de base */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

body {
    font-family: Inter, sans-serif;
    color: #333;
    line-height: 1.6;
    background-color: #E9E8E8;
}

a {
    text-decoration: none;
    color: #333;
}

/*a:hover {*/
/*    color: #1b73b4;*/
/*}*/

/* Header */
/*.header {*/
/*    background-color: #fff;*/
/*    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);*/
/*}*/

/*.header .logo {*/
/*    display: inline-block;*/
/*    padding: 15px 30px;*/
/*}*/

/*.header .nav {*/
/*    display: inline-block;*/
/*    float: right;*/
/*}*/

/*.header .nav li {*/
/*    display: inline-block;*/
/*    padding: 15px 20px;*/
/*}*/

/*!* Home *!*/
/*.home {*/
/*    text-align: center;*/
/*    padding: 50px 0;*/
/*}*/

/*.home h1 {*/
/*    font-size: 40px;*/
/*    margin-bottom: 20px;*/
/*}*/

/*.home p {*/
/*    font-size: 20px;*/
/*    margin-bottom: 20px;*/
/*}*/

/*!* Services *!*/
/*.services {*/
/*    text-align: center;*/
/*    padding: 50px 0;*/
/*}*/

/*.services h2 {*/
/*    font-size: 30px;*/
/*    margin-bottom: 20px;*/
/*}*/

/*.services .service {*/
/*    display: inline-block;*/
/*    width: 30%;*/
/*    margin: 0 2%;*/
/*}*/

/*.services .service img {*/
/*    width: 100%;*/
/*}*/

/*.services .service h3 {*/
/*    font-size: 20px;*/
/*    margin-bottom: 10px;*/
/*}*/

/*!* AboutUs *!*/
/*.about-us {*/
/*    text-align: center;*/
/*    padding: 50px 0;*/
/*}*/

/*.about-us h2 {*/
/*    font-size: 30px;*/
/*    margin-bottom: 20px;*/
/*}*/

/*.about-us p {*/
/*    font-size: 20px;*/
/*    margin-bottom: 20px;*/
/*}*/

/*!* Contact *!*/
/*.contact {*/
/*    text-align: center;*/
/*    padding: 50px 0;*/
/*}*/

/*.contact h2 {*/
/*    font-size: 30px;*/
/*    margin-bottom: 20px;*/
/*}*/

/*.contact form {*/
/*    display: inline-block;*/
/*    width: 60%;*/
/*    margin: 0 auto;*/
/*}*/

/*.contact form input,*/
/*.contact form textarea {*/
/*    width:100%;*/
/*    padding: 10px;*/
/*    margin-bottom: 20px;*/
/*    border: 1px solid #ddd;*/
/*    box-sizing: border-box;*/
/*}*/

/*.contact form button {*/
/*    display: inline-block;*/
/*    background-color: #1b73b4;*/
/*    color: #fff;*/
/*    padding: 10px 20px;*/
/*    border: none;*/
/*    cursor: pointer;*/
/*}*/

/*.contact form button:hover {*/
/*    background-color: #1972a4;*/
/*}*/

/* Footer */
/*.footer {*/
/*    background-color: #333;*/
/*    color: #fff;*/
/*    text-align: center;*/
/*    padding: 30px 0;*/
/*}*/

/*.footer p {*/
/*    margin: 0;*/
/*}*/
