/*@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');*/

/** {*/
/*  margin: 0;*/
/*  padding: 0;*/
/*  box-sizing: border-box;*/
/*}*/

/*body {*/
/*  font-family: 'Roboto', sans-serif;*/
/*  background-color: #F4F4F4;*/
/*}*/

/*.header {*/
/*  height: 7vh;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  !*background-image: url('https://images.unsplash.com/32/Mc8kW4x9Q3aRR3RkP5Im_IMG_4417.jpg?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80');*!*/
/*  background-size: cover;*/
/*  background-position: center;*/
/*}*/

/*.nav-bar {*/
/*  display: flex;*/
/*  justify-content: space-between;*/
/*  align-items: center;*/
/*  width: 90%;*/
/*  max-width: 1200px;*/
/*  margin: 20px 0;*/
/*}*/

/*.nav-bar ul {*/
/*  list-style: none;*/
/*  display: flex;*/
/*}*/

/*.nav-bar ul li a {*/
/*  text-decoration: none;*/
/*  color: white;*/
/*  font-weight: 500;*/
/*  font-size: 18px;*/
/*  margin-left: 20px;*/
/*  transition: all 0.3s ease-in-out;*/
/*}*/

/*.nav-bar ul li a:hover {*/
/*  color: #FFB900;*/
/*}*/

/*.hero {*/
/*  width: 90%;*/
/*  max-width: 1200px;*/
/*  margin: 0 auto;*/
/*  text-align: center;*/
/*  color: white;*/
/*}*/

/*.hero h1 {*/
/*  font-size: 54px;*/
/*  font-weight: 700;*/
/*  margin-bottom: 20px;*/
/*  line-height: 1.2;*/
/*}*/

/*.subtitle {*/
/*  font-size: 24px;*/
/*  font-weight: 400;*/
/*  margin-bottom: 40px;*/
/*  line-height: 1.5;*/
/*}*/

/*.cta-button {*/
/*  background-color: #FFB900;*/
/*  color: white;*/
/*  border: none;*/
/*  border-radius: 25px;*/
/*  font-weight: 500;*/
/*  font-size: 18px;*/
/*  padding: 10px 20px;*/
/*  cursor: pointer;*/
/*  transition: all 0.3s ease-in-out;*/
/*}*/

/*.cta-button:hover {*/
/*  background-color: white;*/
/*  color: #FFB900;*/
/*}*/
