.footer{
    border-top: 1px solid #D9D9D9;
    /*height: 10em;*/
    width: 94%;
    margin: auto;

    display: flex;
    flex-flow: row nowrap;

    justify-content: center;
    align-items: flex-start;
}

.footer-container{
    display: flex;
    flex-flow: row wrap;

    justify-content: space-around;
    align-items: flex-start;

    padding: 2em 0;
    width: 80%;
    max-width: 90em;

    /*background-color: #b7b7b7;*/
}

.footer-col{
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1.25em;

    /*max-width: 7.7em;*/

    /*width: 20%;*/
    /*height: 100%;*/
}

.footer-clickable{
    cursor: pointer;
}

.snow-title{
    font-family: Poppins, Roboto, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1.75em;
    /*line-height: 33px;*/
    color: #000000;
}


@media (max-width: 1024px) {

    .footer_p2{
        font-size: 1em !important;
    }

    .footer-container{
        width: 100%;

    }

}


@media (max-width: 390px) {


    .footer-container{
        flex-flow: column-reverse nowrap;
        //margin: auto;
        width: max-content;
        gap: 1em;
    }

}