.h1{
    font-family: Poppins, Roboto, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 4.5em;
    line-height: 111%;

    color: #050505;
}


.h2{
    font-family: Poppins, Roboto, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 3em;
    line-height: 129%;

    color: #050505;
}


.h3{
    font-family: Poppins, Roboto, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 2em;
    line-height: 134%;

    color: #000000;
}


.h4{
    font-family: Inter, Roboto, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 2em;
    line-height: 53%;

    color: #030303;
}

.x1{
    font-family: Inter, Roboto, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.8125em;
    line-height: 131%;

    letter-spacing: 0.155em;
    text-transform: uppercase;

    color: #848484;
}


.p1{
    font-family: Inter, Roboto, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.25em;
    line-height: 130%;

    color: #030303;
}

.p2{
    font-family: Inter, Roboto, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 131%;

    letter-spacing: -0.005em;

    color: #000000;
}

.l1{
    font-family: Inter, Roboto, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 131%;

    letter-spacing: -0.005em;

    color: #000000;

}

@media screen and (max-width: 1550px) {


    .h2{
        font-size: 2.5em;

    }

    .p1{
        font-size: 1.125em;

    }

}


@media screen and (max-width: 1024px) {





    .h1{
        font-size: 4em;

    }

    //
    .h2{
        font-size: 1.68em;

    }

    .h3{
        font-size: 1.34em;

    }

    .h4{
        font-size: 1.8em;
        line-height: 134%;

    }

    .x1{
        font-size: 0.6875em;

    }



    //
    .p1{
        font-size: 0.77em;

    }

    .p2{
        font-size: 0.875em;

    }



}

@media screen and (max-width: 510px) {





    .h1{
        font-size: 3.5em;

    }

    //
    //.h2{
    //    font-size: 1.68em;
    //
    //}
    //
    //.h3{
    //    font-size: 1.34em;
    //
    //}
    //
    //.h4{
    //    font-size: 1.8em;
    //
    //}
    //
    //.x1{
    //    font-size: 0.6875em;
    //
    //}
    //
    //
    //
    ////
    //.p1{
    //    font-size: 0.77em;
    //
    //}
    //
    //.p2{
    //    font-size: 0.875em;
    //
    //}
    //


}


@media screen and (max-width: 445px) {





    .h1{
        font-size: 3em;

    }

    //
    //.h2{
    //    font-size: 1.68em;
    //
    //}
    //
    //.h3{
    //    font-size: 1.34em;
    //
    //}
    //
    //.h4{
    //    font-size: 1.8em;
    //
    //}
    //
    //.x1{
    //    font-size: 0.6875em;
    //
    //}
    //
    //
    //
    ////
    //.p1{
    //    font-size: 0.77em;
    //
    //}
    //
    //.p2{
    //    font-size: 0.875em;
    //
    //}
    //


}
