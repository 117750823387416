.navbar {
    background-color: #E9E8E8;
    /*border-bottom: 2px solid #ccc;*/
    padding: 20px 0;

    width: 80%;
    max-width: 95em;
    height: 4.5em;


    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    gap: 2em;

}

.backdrop_visible{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(4px); // Ajustez la valeur pour obtenir l'effet de flou souhaité
    z-index: -1;
    //background-color: #5d6c7b;
}

.backdrop_hidden{
    display: none;
}

.left-header{
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 5em;

    /*background-color: #cbcbcb;*/

}

.nav-header {
    z-index: 999;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #E9E8E8;
    box-shadow: 0px 0px 39px rgba(0, 0, 0, 0.1);

    /*background-color: #3b87ac;*/


    position: fixed;
    top: 0;
    left: 0;
}

.navbar-menu{
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    gap: 2em;
    list-style-type: none;
    /*background-color: #bbbbbb;*/
}

li .active, .mobile-menu-item .active{
    color: #2548F1 !important;
    font-weight: bold;
}

.navbar-toggle {
    display: none;
}

.navbar-toggle span {
    background-color: #333;
    display: block;
    height: 2px;
    margin-bottom: 5px;
    width: 20px;
}

/*.navbar-nav {*/
/*    margin: 0;*/
/*    padding: 0;*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    justify-content: flex-end;*/
/*    align-items: center;*/
/*}*/

.navbar-item {
    margin: 0 10px;
}

.navbar-link, .mobile-menu-item p{
    color: #000000;
    font-size: 1em;
    text-decoration: none;

    font-family: Inter, Roboto, sans-serif;
    font-style: normal;
    font-weight: bold;

    cursor: pointer;
}

.navbar-link:hover {
    color: #2548F1;
}


.navbar-logo {
    font-family: Poppins, Roboto, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1.75em;
    line-height: 33px;
    color: #000000;
}

/*.navbar-cta{*/
/*    align-self: ;*/
/*    */
/*}*/

.menu_icon{
    width: 2.5em;
}

.mobile-menu, .menu_icon{
    display: none;
}


@media (max-width: 1024px) {


    .menu_icon{
        display: block;
    }



    .mobile-menu-item p{
        //color: red !important;
        padding-left: 3em;

    }


    .mobile-menu {
        position: fixed;
        top: 5%;
        left: 10%;
        width: 80%;
        height: 8em;
        background-color: #F2F2F2;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        z-index: -1;
        border-radius: 0.6em;
        padding-top: 3.5em;
    }

    .mobile-menu-item-container {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    .mobile-menu-item {
        text-align: center;
        margin-bottom: 1em;
    }

    @media (max-width: 768px) {
        .navbar-menu.open {
            display: block;
        }

        .menu-icon.open {
            /* Adjust the opened menu icon styles here */
        }

        .mobile-menu {
            display: flex;
        }
    }





        .navbar{
        height: 2.5em;

    }

    .navbar-cta{
        display: none;
    }



    //.navbar-toggle {
    //    display: block;
    //    float: right;
    //    margin-top: 10px;
    //    margin-right: 10px;
    //}

    .navbar-menu {
        display: none;
        //position: absolute;
        //top: 100%;
        //left: 0;
        //width: 100%;
        //background-color: #fff;
        //padding: 20px;
        //box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }

    //.navbar-menu.open {
    //    display: block;
    //}
    //
    //.nav-item {
    //    margin: 10px 0;
    //}
    //
    //.nav-link {
    //    display: block;
    //    font-size: 18px;
    //    padding: 10px;
    //    border-bottom: 1px solid #ccc;
    //}
    //
    //.nav-link:hover {
    //    background-color: #f9f9f9;
    //}
}





