/* Frame 1 */

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");

$running_man_image_path: '../../assets/img/polygon_running_man.jpg';

.home{
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    //background-color: #3b87ac;
}

.mainContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //margin: 2em auto 10em auto;
    margin-top: 2em;
    margin-bottom: 10em;
    /*width: 1440px;*/
    /*height: 3845px;*/
    /*background-color: rgba(233, 232, 232, 1);*/
    width: 80%;
    max-width: 95em;
    //background-color: rgb(128, 128, 128);
}

.hero{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin: 5.2em 0 2em 0;
    width: 100%;
    /*background-color: rgb(201, 201, 201);*/
}

.heroImage{
    width: 45%;
    border-radius: 23px;
}

.innerHero{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 10em 0 7em 0;
    width: 100%;
    /*background-color: rgb(169, 169, 169);*/
}


.card{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    gap: 10px;

    width: 90%;
    padding: 2.6em 3.1em 2.6em 3.1875em;

    /*margin: 8.5em 0 19.375em 0;*/


    /* white */

    background: #FFFFFF;
    box-shadow: 0px 4px 32px rgba(51, 51, 51, 0.1);
    border-radius: 16px;
}

.card_top{

    margin: 8.5em 0 19.375em 0;
    /*margin: 2em 0 2em 0;*/

}
.card_bottom{
    margin: 15em 0 2em 0;
}

.innerCard:last-of-type{
    max-width: 40em;
}

.card_top::after{
    content: "";
    position: absolute;
    top: 30px;
    left: 2%;
    width: 96%;
    height: 97.5%;
    background: #F2F2F2;
    /*background: #d34a4a;*/
    box-shadow: 0px 4px 32px rgba(51, 51, 51, 0.1);
    border-radius: 16px;

    z-index: -1;
}


.innerCard{
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;

    max-width: 38em;

    /*width: 100%;*/
    //background-color: rgb(201, 201, 201);
    padding-top: 2.9em;

    //@media (max-width: 1024px) {
    //
    //}
}


.content1{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    gap: 5em;
    width: 86%;
    /*background-color: rgb(201, 201, 201);*/

    margin-bottom: 16.125em;
}

.content2{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    gap: 5em;
    width: 86%;
    /*background-color: rgb(201, 201, 201);*/
}



.content_container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1em;
    /*width: 35%;*/
    //background-color: rgb(222, 222, 222);
}







.content_text1{
    max-width: 32.3em;
    /*background-color: #3b87ac;*/
}

.content_text2{
    max-width: 30em;
    /*background-color: #3b87ac;*/
}


.image_container_glow{
    max-width: 38em !important;

    border-radius: 1.9em;
    /*border-radius: 16px;*/
}

.first_image_card{
    /*width: 36%;*/
    /*object-fit: contain;*/
    /*height: aspect-ratio(1/7);*/
    /*height: 80%;*/
    width: 40%;
    /*background-color: #5d6c7b;*/
    /*overflow: hidden;*/
    /*height: auto;*/
    /*position: absolute;*/
    /*top: 50%;*/
    /*right: 3.3%;*/
    /*transform: translate(0%, -50%);*/
}

.second_image_card{
    width: 40%;
    align-self: center !important;
}

//.card_bottom{
//    align-items: center !important;
//}



.p1_card{
    display: flex;
    flex-flow: row wrap;
    /*background-color: #a2a2a2;*/
    max-width: 20em;

}

.p2_card{
    max-width: 22em !important;

}


.midSection{
    position: absolute;
    bottom: -30%;
    /*width: 5%;*/
    /*height: 2em;*/
    /*background-color: #d34a4a;*/
}


.hero_h1{
    max-width: 8em;
    //background-color: #5d6c7b;
}

.hero_h1_mobile{
    display: none;
}

.mobile_only{
    display: none !important;
}



@media (max-width: 1550px) {

    .content1, .content2{
        width: 100%;
    }

    .p1_card{
        max-width: 100% !important;
    }

    .innerCard{
        padding-top: 0;
        max-width: 100% !important;
        //justify-content: center;
    }

    .card{
        width: calc(89% - 3em)!important;
        padding: 1.37em 1.5em;
        align-items: center;

        //a enlever
        //margin-bottom: 35em;
        //margin-bottom: 0em;
    }

    .image_container_glow{
        width: 46%;
    }

    .mobile_only{
        display: none !important;
    }

}

@media (max-width: 1024px) {

    .content1, .content2{
        flex-flow: row wrap;
        justify-content: center;
        gap: 1.7em;
        width: 100%;
    }

    .content1{
        flex-flow: column-reverse wrap !important;
    }

    .innerCard{
        display: flex;
        flex-flow: column wrap;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 0.3em;
        width: 100%;

    }


    .mainContainer{
        width: 100%;
        //background-color: #5d6c7b;
        margin-bottom: 6em;
    }

    .mobile_hidden, .content_cta, .hero_x1, .hero_h1{
        display: none !important;
    }

    .mobile_only{
        display: block !important;
    }

    //.card_top::after{
    //
    //    $width: 90%;
    //    width: $width;
    //    height: $width* 2;
    //
    //    content: "";
    //    position: absolute;
    //    top: calc(100% + 1.7em);
    //    left: 5%;
    //
    //    box-shadow: 0px 4px 32px rgba(51, 51, 51, 0.1);
    //    border-radius: 1.9em;
    //
    //    z-index: -1;
    //
    //    background-color: rgb(241, 196, 196);
    //    background-image: url($running_man_image_path);
    //    background-size: cover;
    //    background-repeat: no-repeat;
    //    background-position: center;
    //
    //
    //    //padding-top: 50%;
    //}
    //.card_top::before{
    //
    //    content: "";
    //    position: absolute;
    //    top: calc(100% - 1em);
    //    left: 2%;
    //
    //    $width: 96%;
    //    width: $width;
    //    height: calc((96% * 2) + 2.4em);
    //
    //
    //    background-color: #F2F2F2;
    //    //background-color: #F2F2F2;
    //    box-shadow: 0px 4px 32px rgba(51, 51, 51, 0.1);
    //    border-radius: 1.9em;
    //    z-index: -1;
    //    //padding: 1em;
    //}

    .card_top, .card_bottom{
        margin-top: 5em;
        margin-bottom: 0;
    }

    .mobile_img_container{
        margin-bottom: 19.4em;
    }

    .image_container_glow{
        width: 77%;
    }

    .content_container{
        width: 81.5%;
        gap: 0.4em;
    }

    .content_text2{
        max-width: 100%;
    }


    .hero {
        flex-flow: column nowrap;
        //background-color: #5d6c7b;
        margin-bottom: 5em;
    }

    .innerHero{
        margin-top: 2em;
        margin-bottom: unset;
        align-items: center;
    }

    .hero_h1{
        max-width: 85%;
        //background-color: #5d6c7b;
    }

    .hero_h1_mobile{
        display: block !important;
        flex-wrap: nowrap;
    }

    .heroImage{
        align-self: center;
        width: 90%;
        border-radius: 23px;
    }

    .hero_cta{
        display: flex !important;
        width: 80% !important;
        align-items: center !important;
        justify-content: center !important;
        border-radius: 0.75em !important;
    }

    .mobile_img_container{
        width: calc(90% - 4em);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1em;
        padding-top: 3em;
        margin-top: -2em;
        margin-bottom: 6em;

        background-color: #F2F2F2;
        //background-color: #F2F2F2;
        box-shadow: 0px 4px 32px rgba(51, 51, 51, 0.1);
        border-radius: 0.8em;
    }

    .mobile_img_container:last-of-type{
        margin-bottom: 0;
    }

    .mobile_img_running{
        width: 100%;
        border-radius: 0.6em;
        max-width: 100% !important;

    }

    .card_bottom{
        border-radius: 0.6em;
    }

    .card_top::after , .card_bottom::after{
        display: none;

    }

    .content1{
        margin-bottom: 6em;
    }


}







/*p {*/
/*    margin: 0;*/
/*}*/

/*.frame-1 {*/
/*    position: relative;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    justify-content: flex-start;*/
/*    align-items: flex-start;*/
/*    width: 1440px;*/
/*    height: 3845px;*/
/*    box-sizing: border-box;*/
/*    background-color: rgba(233, 232, 232, 1);*/
/*}*/
/*.button {*/
/*    position: absolute;*/
/*    top: 31px;*/
/*    left: 1112px;*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    gap: 10px;*/
/*    width: 166px;*/
/*    height: 53px;*/
/*    padding: 9px 17px;*/
/*    border-radius: 12px;*/
/*    box-sizing: border-box;*/
/*    background-color: rgba(37, 72, 241, 1);*/
/*}*/
/*.supporter-snow {*/
/*    color: rgba(255, 255, 255, 1);*/
/*    font-size: 16px;*/
/*    line-height: 163%;*/
/*    font-family: Inter, sans-serif;*/
/*    font-weight: 600;*/
/*}*/

/*.button-1 {*/
/*    position: absolute;*/
/*    top: 1293px;*/
/*    left: 196px;*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    gap: 10px;*/
/*    width: 166px;*/
/*    height: 53px;*/
/*    padding: 9px 17px;*/
/*    border-radius: 12px;*/
/*    box-sizing: border-box;*/
/*    background-color: rgba(37, 72, 241, 1);*/
/*}*/
/*.supporter-snow-1 {*/
/*    color: rgba(255, 255, 255, 1);*/
/*    font-size: 16px;*/
/*    line-height: 163%;*/
/*    font-family: Inter, sans-serif;*/
/*    font-weight: 600;*/
/*}*/

/*.une-solution-innovante {*/
/*    position: absolute;*/
/*    top: 1080px;*/
/*    left: 196px;*/
/*    color: rgba(5, 5, 5, 1);*/
/*    font-size: 48px;*/
/*    line-height: 129%;*/
/*    font-family: Poppins, sans-serif;*/
/*    font-weight: 600;*/
/*}*/

/*.une-reconnaissance-de-g {*/
/*    position: absolute;*/
/*    top: 1218px;*/
/*    left: 196px;*/
/*    color: rgba(3, 3, 3, 1);*/
/*    font-size: 20px;*/
/*    line-height: 130%;*/
/*    font-family: Inter, sans-serif;*/
/*    font-weight: 400;*/
/*}*/

/*.group-729 {*/
/*    position: absolute;*/
/*    top: 3227px;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    width: 419.35px;*/
/*    height: 137px;*/
/*    box-sizing: border-box;*/
/*}*/
/*.ne-ratez-pas-le-prochain {*/
/*    color: rgba(3, 3, 3, 1);*/
/*    font-size: 20px;*/
/*    line-height: 130%;*/
/*    font-family: Inter, sans-serif;*/
/*    font-weight: 400;*/
/*}*/

/*.button-2 {*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    gap: 10px;*/
/*    width: 166px;*/
/*    height: 53px;*/
/*    padding: 9px 17px;*/
/*    border-radius: 12px;*/
/*    box-sizing: border-box;*/
/*    background-color: rgba(37, 72, 241, 1);*/
/*}*/
/*.supporter-snow-2 {*/
/*    color: rgba(255, 255, 255, 1);*/
/*    font-size: 16px;*/
/*    line-height: 163%;*/
/*    font-family: Inter, sans-serif;*/
/*    font-weight: 600;*/
/*}*/

/*.group-152 {*/
/*    position: absolute;*/
/*    top: 623px;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: flex-end;*/
/*    width: 879.51px;*/
/*    height: 2139px;*/
/*    box-sizing: border-box;*/
/*}*/
/*.button-3 {*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    gap: 10px;*/
/*    width: 166px;*/
/*    height: 53px;*/
/*    padding: 9px 17px;*/
/*    border-radius: 12px;*/
/*    box-sizing: border-box;*/
/*    background-color: rgba(37, 72, 241, 1);*/
/*}*/
/*.supporter-snow-3 {*/
/*    color: rgba(255, 255, 255, 1);*/
/*    font-size: 16px;*/
/*    line-height: 163%;*/
/*    font-family: Inter, sans-serif;*/
/*    font-weight: 600;*/
/*}*/

/*.button-4 {*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    gap: 10px;*/
/*    width: 166px;*/
/*    height: 53px;*/
/*    padding: 7px 15px;*/
/*    border: 1px solid rgba(3, 3, 3, 1);*/
/*    border-radius: 12px;*/
/*    box-sizing: border-box;*/
/*}*/
/*.supporter-snow-4 {*/
/*    color: rgba(3, 3, 3, 1);*/
/*    font-size: 16px;*/
/*    line-height: 163%;*/
/*    font-family: Inter, sans-serif;*/
/*    font-weight: 600;*/
/*}*/

/*.bient-tplus-de100-objet {*/
/*    position: absolute;*/
/*    top: 166px;*/
/*    left: 272px;*/
/*    color: rgba(3, 3, 3, 1);*/
/*    font-size: 32px;*/
/*    line-height: 53%;*/
/*    font-family: Inter, sans-serif;*/
/*    font-weight: 600;*/
/*}*/

/*.plongez-dans-une-exp-rie {*/
/*    position: absolute;*/
/*    top: 1919px;*/
/*    left: 2px;*/
/*    color: rgba(0, 0, 0, 1);*/
/*    font-size: 16px;*/
/*    line-height: 131%;*/
/*    font-family: Inter, sans-serif;*/
/*    font-weight: 400;*/
/*    letter-spacing: -0.08px;*/
/*}*/

/*.ux {*/
/*    position: absolute;*/
/*    top: 1776px;*/
/*    left: 2px;*/
/*    color: rgba(127, 127, 127, 1);*/
/*    font-size: 13px;*/
/*    line-height: 131%;*/
/*    font-family: Inter, sans-serif;*/
/*    font-weight: 700;*/
/*    letter-spacing: 2.02px;*/
/*    text-transform: uppercase;*/
/*}*/

/*.une-exp-rience-unique-au {*/
/*    position: absolute;*/
/*    top: 1814px;*/
/*    left: 2px;*/
/*    color: rgba(0, 0, 0, 1);*/
/*    font-size: 32px;*/
/*    line-height: 134%;*/
/*    font-family: Poppins, sans-serif;*/
/*    font-weight: 600;*/
/*}*/

/*.group-824 {*/
/*    position: absolute;*/
/*    top: 1795px;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    width: 517.52px;*/
/*    height: 346px;*/
/*    box-sizing: border-box;*/
/*}*/
/*.fluidit {*/
/*    color: rgba(127, 127, 127, 1);*/
/*    font-size: 13px;*/
/*    line-height: 131%;*/
/*    font-family: Inter, sans-serif;*/
/*    font-weight: 700;*/
/*    letter-spacing: 2.02px;*/
/*    text-transform: uppercase;*/
/*}*/

/*.un-contr-le-naturel-et-in {*/
/*    color: rgba(0, 0, 0, 1);*/
/*    font-size: 32px;*/
/*    line-height: 134%;*/
/*    font-family: Poppins, sans-serif;*/
/*    font-weight: 600;*/
/*}*/

/*.snow-souhaite-rinvente {*/
/*    color: rgba(0, 0, 0, 1);*/
/*    font-size: 16px;*/
/*    line-height: 131%;*/
/*    font-family: Inter, sans-serif;*/
/*    font-weight: 400;*/
/*    letter-spacing: -0.08px;*/
/*}*/

/*.button-5 {*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    gap: 10px;*/
/*    width: 166px;*/
/*    height: 53px;*/
/*    padding: 7px 15px;*/
/*    border: 1px solid rgba(3, 3, 3, 1);*/
/*    border-radius: 12px;*/
/*    box-sizing: border-box;*/
/*}*/
/*.supporter-snow-5 {*/
/*    color: rgba(3, 3, 3, 1);*/
/*    font-size: 16px;*/
/*    line-height: 163%;*/
/*    font-family: Inter, sans-serif;*/
/*    font-weight: 600;*/
/*}*/

/*.snow {*/
/*    position: absolute;*/
/*    top: 42px;*/
/*    left: 126px;*/
/*    color: rgba(0, 0, 0, 1);*/
/*    font-size: 28px;*/
/*    line-height: 118%;*/
/*    font-family: Poppins, sans-serif;*/
/*    font-weight: 600;*/
/*}*/

/*.group-632 {*/
/*    position: absolute;*/
/*    top: 46px;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: center;*/
/*    width: 702.07px;*/
/*    height: 3160px;*/
/*    box-sizing: border-box;*/
/*}*/
/*.group-349 {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: flex-end;*/
/*    width: fit-content;*/
/*    box-sizing: border-box;*/
/*}*/
/*.group-674 {*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    width: fit-content;*/
/*    box-sizing: border-box;*/
/*}*/
/*.home {*/
/*    color: rgba(0, 0, 0, 1);*/
/*    font-size: 16px;*/
/*    line-height: 134%;*/
/*    font-family: Inter, sans-serif;*/
/*    font-weight: 600;*/
/*}*/

/*.pricing {*/
/*    color: rgba(0, 0, 0, 1);*/
/*    font-size: 16px;*/
/*    line-height: 134%;*/
/*    font-family: Inter, sans-serif;*/
/*    font-weight: 600;*/
/*}*/

/*.about-us {*/
/*    color: rgba(0, 0, 0, 1);*/
/*    font-size: 16px;*/
/*    line-height: 134%;*/
/*    font-family: Inter, sans-serif;*/
/*    font-weight: 600;*/
/*}*/

/*.contact {*/
/*    color: rgba(0, 0, 0, 1);*/
/*    font-size: 16px;*/
/*    line-height: 134%;*/
/*    font-family: Inter, sans-serif;*/
/*    font-weight: 600;*/
/*}*/

/*.group-278 {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    width: fit-content;*/
/*    box-sizing: border-box;*/
/*}*/
/*.explore-new-capabiliti {*/
/*    color: rgba(132, 132, 132, 1);*/
/*    font-size: 13px;*/
/*    line-height: 131%;*/
/*    font-family: Inter, sans-serif;*/
/*    font-weight: 700;*/
/*    letter-spacing: 2.02px;*/
/*    text-transform: uppercase;*/
/*}*/

/*.reprenez-le-contr-le-dun {*/
/*    color: rgba(5, 5, 5, 1);*/
/*    font-size: 72px;*/
/*    line-height: 111%;*/
/*    font-family: Poppins, sans-serif;*/
/*    font-weight: 600;*/
/*}*/

/*.snow-lavenir-de-la-domot {*/
/*    color: rgba(3, 3, 3, 1);*/
/*    font-size: 20px;*/
/*    line-height: 85%;*/
/*    font-family: Inter, sans-serif;*/
/*    font-weight: 400;*/
/*}*/

/*.embarquez-dans-luniver {*/
/*    color: rgba(5, 5, 5, 1);*/
/*    font-size: 48px;*/
/*    line-height: 129%;*/
/*    font-family: Poppins, sans-serif;*/
/*    font-weight: 600;*/
/*}*/
